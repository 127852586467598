<template>
  <div id="daily_energy">
    <div class="card">
      <div class="card-content" style="text-align: left;">
        <strong class="title is-4">
          Daily Energy
        </strong>
        <hr>
        <div class="columns is-multiline" v-if="ready">
          <div class="column is-one-third-desktop" v-for="(val,key) in branchs" :key="key">
            <article class="message is-info" @click="branch=val">
              <div class="message-header py-1">
                <p class="w-100 cursor_pointer">
                  <i class="fas fa-building"></i>
                  {{val.name}}
                </p>
              </div>
              <div class="message-body has-text-left p-3 cursor_pointer">
                <small class="is-size-6 is-size-7-mobile">{{val.detail}}</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-5" v-if="branch.id">
      <div class="card-content pb-0" style="text-align: left;">
        <strong class="title is-4">
          <i class="fas fa-building"></i>
          {{branch.name}}
          <br class="is-hidden-tablet">
          <small class="subtitle">( {{branch.detail}} )</small>
        </strong>
        <button type="button" class="button is-small is-primary" style="float:right;" @click="refreshButton">
          <span class="mr-2 is-hidden-mobile">Refresh</span>
          <i class="fas fa-retweet"></i>
        </button>
        <hr>
      </div>
      <div class="columns is-desktop">
        <div class="column is-full pb-0">
          <button class="button is-small vpdDateButton" @click="previusDate"> <i class="fas fa-caret-left"></i> </button>
          <date-pick ref="date_pick" v-model="inputDate" :inputAttributes="{readonly: true}"></date-pick>
          <button class="button is-small vpdDateButton" @click="nextDate"> <i class="fas fa-caret-right"></i> </button>
        </div>
      </div>
      <div class="columns is-desktop">
        <div class="column is-full pt-0" v-if="renderChart">
          <div class="columns is-multiline">
            <div class="column is-full-desktop">
              <article class="message is-info mx-5 mt-5">
                <div class="message-header py-1">
                  <p class="w-100 has-text-left">
                    <i class="fas fa-building"></i>
                    {{branch.name}}
                    <br class="is-hidden-tablet">
                    <small class="is-size-7">({{branch.detail}})</small>
                  </p>
                </div>
                <div class="message-body has-text-left py-4">
                  <b>Total Energy : {{total_energy}} Kwh</b>
                  <br class="is-hidden-tablet">
                  <small class="is-hidden-mobile">, </small>
                  {{node_in_branch.length}} Nodes
                  <br class="is-hidden-tablet">
                  <small class="is-hidden-mobile">, </small>
                  {{count_device_in_branch}} Devices
                </div>
              </article>
            </div>
            <hr>
            <div class="column is-full-desktop" v-for="(val,key) in node_in_branch" :key="key">
              <article class="message is-link mx-5 mt-5">
                <div class="message-header py-1">
                  <p class="w-100 has-text-left">
                    <i class="fas fa-code-branch"></i>
                    {{val.name}} ({{val.mac}})
                    <br class="is-hidden-tablet">
                    <small class="is-size-7">({{val.detail}})</small>
                  </p>
                </div>
                <div class="message-body has-text-left py-4">

                  <div class="columns is-multiline">
                    <div class="column is-one-third-desktop" v-for="(device,index) in device_from_node(val.id)" :key="index">
                      <article class="message" :class="{'is-success':parseInt(device.enable),'is-danger':!parseInt(device.enable)}">
                        <div class="message-header py-1">
                          <p class="w-100">
                            <i class="fas fa-solar-panel"></i>
                            Device{{device.number}}
                            <br class="is-hidden-tablet">
                            <small class="is-size-7" v-if="device.detail">({{device.detail}})</small>
                          </p>
                        </div>
                        <div class="message-body has-text-left py-2 is-size-6 is-size-7-mobile">
                          {{daily_energy(device)}} Kwh
                        </div>
                      </article>
                    </div>
                  </div>

                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="column is-full py-0" v-else>
          <button class="button is-white is-loading" style="width:100%; height:375px;"></button>
        </div>
      </div>
    </div>
    <!--
    <pre>{{loaded}}</pre>
    <pre>{{branchs}}</pre>
    <pre>{{nodes}}</pre>
    <pre>{{devices}}</pre>
     -->
  </div>
</template>

<script>
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';

export default {
  name: 'MemberDailyEnergy',
  props: {
    userData: Object,
    loaded: Object,
    branchs: Array,
    nodes: Array,
    devices: Array,
  },
  components: { DatePick },
  data(){
    return {
      branch: {},
      ready: false,
      renderChart: false,
      inputDate: this.formatDate(new Date()),
      timeData: null,
      firstLog: [],
      lastLog: [],
      today: null,
      yesterday: null,
    }
  },
  mounted(){
    if(!this.loaded.get_branch){
      this.$emit('get_branch');
    }else{
      if(!this.nodes.length){
        this.get_node_each_branchs();
      }
      this.ready = true;
    }
  },
  methods:{
    refreshButton(){
      this.renderChart = false;
      this.get_daily_energy();
    },
    async get_daily_energy(){
      if(!this.devices.length){
        this.get_devices_each_nodes();
        if(!this.ready){
          setTimeout(() => this.get_daily_energy(), 1000);
        }
      }else{
        var url = this.api+'user/get_daily_energy/'+this.secret;
        var data = new FormData();
        let d_id = [];
        this.devices.forEach((e) => {
          d_id.push(e.id);
        });
        data.set("d_id", JSON.stringify(d_id));
        data.set("date", this.inputDate);
        data.set("secret", this.secret);
        this.Axios.post(url, data)
        .then((response)=>{
          console.log(response.data);
          if(response.data.error==0){
            this.firstLog = response.data.first_log;
            this.lastLog = response.data.last_log;
            this.today = response.data.today;
            this.yesterday = response.data.yesterday;
          }
          setTimeout(() => this.renderChart=true, 100);
        })
        .catch((error)=>{
          console.error(error);
        })
        // this.node.forEach((e) => {
        //   console.log(e.id);
        // });
        // setTimeout(() => this.renderChart=true, 500);
      }
    },
    device_from_node(n_id){
      return this.devices.filter(device => { return parseInt(device.n_id)==parseInt(n_id) });
    },
    get_node_each_branchs(){
      this.branchs.forEach((e,i) => {
        setTimeout(() => this.get_node(e.id), 100*i)
      });
    },
    get_devices_each_nodes(){
      this.nodes.forEach((e,i) => {
        setTimeout(() => this.get_device(e.id), 100*i)
      });
    },
    get_node(n_id){
      this.$emit('get_node',n_id)
    },
    get_device(d_id){
      this.$emit('get_device',d_id)
    },
    formatDate(date=null) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [year, month, day].join('-');
    },
    previusDate(){
      var d = new Date( this.inputDate );
      d.setDate(d.getDate() - 1);

      this.inputDate = new Date(d).toISOString().slice(0,10);
    },
    nextDate(){
      var d = new Date( this.inputDate );
      d.setDate(d.getDate() + 1);

      this.inputDate = new Date(d).toISOString().slice(0,10);
    },
    Total_Energy(log){
      const IMP_ENERGY = (log.Imp_Energy!=="-") ? parseFloat(log.Imp_Energy) : parseInt(0);
      const EXP_ENERGY = (log.Exp_Energy!=="-") ? parseFloat(log.Exp_Energy) : parseInt(0);
      return IMP_ENERGY + EXP_ENERGY;
    },
    daily_energy(device){
      // console.log("id", device.id);
      let firstLog = null;
      let lastLog = null;

      let index1 = this.firstLog.findIndex(x => x.id == device.id);
      if(index1>-1)firstLog = this.Total_Energy(this.firstLog[index1]);

      let index2 = this.lastLog.findIndex(x => x.id == device.id);
      if(index2>-1)lastLog = this.Total_Energy(this.lastLog[index2]);

      // console.log(firstLog, lastLog);
      let Energy = (!lastLog) ? 0 : parseFloat( firstLog - lastLog ).toFixed(2);
      return (Energy>0) ? Energy : 0;
    },
  },
  computed:{
    node_in_branch(){
      if(!this.branch.id)return [];

      return this.nodes.filter(node => { return parseInt(node.b_id)==parseInt(this.branch.id) });
    },
    count_device_in_branch(){
      if(!this.node_in_branch.length)return 0;

      let count = 0;
      this.node_in_branch.forEach((node) => {
        this.devices.forEach((device) => {
          if(device.n_id==node.id)count++;
        });
      });
      return count;
    },
    total_energy(){
      if(!this.firstLog.length || !this.lastLog.length)return 0;

      let first = 0;
      this.firstLog.forEach((e) => {
        first += this.Total_Energy(e);
      });
      let last = 0;
      this.lastLog.forEach((e) => {
        last += this.Total_Energy(e);
      });
      // let total = parseFloat(first) - parseFloat(last);
      let total = (!last) ? 0 : parseFloat(first) - parseFloat(last);
      let Energy = parseFloat(total).toFixed(2);
      return (Energy>0) ? Energy : 0;
    }
  },
  watch:{
    branch(val){
      if(val.id){
        this.firstLog = [];
        this.lastLog = [];
        this.renderChart = false;
        this.get_daily_energy();

        setTimeout(() => {
          var vdpComponent = document.getElementsByClassName('vdpComponent')[0];
          var inputField = vdpComponent.querySelector('input');
          inputField.classList.add("has-text-centered");
          inputField.classList.add("is-small");
          inputField.classList.add("input");
        }, 1)
      }
    },
    inputDate(){
      clearTimeout(this.timeData)
      this.firstLog = [];
      this.lastLog = [];
      this.renderChart = false;
      this.timeData = setTimeout(() => {
        this.get_daily_energy();
      }, 1000)
    },
    branchs(val,old){
      if(val.length && !old.length){
        this.get_node_each_branchs();
      }
    },
    nodes(val,old){
      if(val.length && !old.length){
        this.get_devices_each_nodes();
      }
    },
    devices(val,old){
      if(val.length && !old.length){
        this.ready = true;
      }
    },
  }
}
</script>

<style media="screen">
.vdpComponent.vdpWithInput input{
  padding: 0 !important;
}
.vdpClearInput{
  display: none !important;
}
.vpdDateButton{
  margin-top: 6px !important;
}
</style>
